// Deep link visiting
export const FOLLOW_DEEP_LINK_EXPERIENCE = 'follow-deep-link';

// Macro Experience
export const VIEW_MACRO_RENDERER_EXPERIENCE = 'view-macro-renderer';
export const VIEW_MACRO_EDITOR_EXPERIENCE = 'view-macro-editor';
export const VIEW_ALL_MACRO_EXPERIENCE = 'view-all-macro';
export const VIEW_JIRA_MACRO_EXPERIENCE = 'view-jira-macro';

// Fabric Page Migration Experiences
export const MIGRATE_PAGE_EXPERIENCE = 'migrate-page';
export const MIGRATE_PAGE_PUBLISH_EXPERIENCE = 'migrate-page/publish';

// Fabric Templates Migration Experiences
export const MIGRATE_TEMPLATE_EXPERIENCE = 'migrate-template';

// Page Comment Experiences
// Note: Add Page Comment covers Edit and Reply To as well
export const ADD_PAGE_COMMENT_EXPERIENCE = 'add-page-comment';
export const ADD_PAGE_COMMENT_LOAD_EXPERIENCE = 'add-page-comment/load';
export const ADD_PAGE_COMMENT_PUBLISH_EXPERIENCE = 'add-page-comment/publish';

export const DELETE_PAGE_COMMENT_EXPERIENCE = 'delete-page-comment';

export const RESOLVE_PAGE_COMMENT_EXPERIENCE = 'resolve-page-comment';

// In progress Comment Experiences
export const CREATE_INLINE_COMMENT_EXPERIENCE = 'create-inline-comment';
export const CREATE_INLINE_COMMENT_INIT_EXPERIENCE = 'create-inline-comment/init';
export const CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE =
	'create-inline-comment/draft-to-publish';
export const CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE =
	'create-inline-comment/attach-comment';

// Being deprecated Inline Comment Experiences
export const ADD_INLINE_COMMENT_EXPERIENCE = 'add-inline-comment';
export const ADD_INLINE_COMMENT_LOAD_EXPERIENCE = 'add-inline-comment/load';
export const ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE = 'add-inline-comment/publish';

// Inline Comment Experiences
export const EDIT_INLINE_COMMENT_EXPERIENCE = 'edit-inline-comment';
export const EDIT_INLINE_COMMENT_LOAD_EXPERIENCE = 'edit-inline-comment/load';
export const EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE = 'edit-inline-comment/publish';
export const VIEW_INLINE_COMMENT_EXPERIENCE = 'view-inline-comment';
export const VIEW_INLINE_COMMENT_TOP_LEVEL_EXPERIENCE = 'view-inline-comment/toplevel';
export const VIEW_INLINE_COMMENT_REPLY_EXPERIENCE = 'view-inline-comment/reply';
export const REPLY_TO_INLINE_COMMENT_EXPERIENCE = 'reply-to-inline-comment';
export const REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE = 'reply-to-inline-comment/load';
export const REPLY_TO_INLINE_COMMENT_PUBLISH_EXPERIENCE = 'reply-to-inline-comment/publish';
export const DELETE_INLINE_COMMENT_EXPERIENCE = 'delete-inline-comment';
export const RESOLVE_INLINE_COMMENT_EXPERIENCE = 'resolve-inline-comment';
export const UNRESOLVE_INLINE_COMMENT_EXPERIENCE = 'unresolve-inline-comment';
export const RESOLVED_INLINE_COMMENTS_DIALOG_SORT_EXPERIENCE =
	'resolved-inline-comments-dialog-sort';
export const REATTACH_INLINE_COMMENT_EXPERIENCE = 'reattach-inline-comment';

// Reactions
export const VIEW_REACTIONS_EXPERIENCE = 'view-reactions';
export const VIEW_REACTION_USER_LIST_EXPERIENCE = 'view-reaction-user-list';
export const ADD_REACTION_EXPERIENCE = 'add-reaction';
export const DELETE_REACTION_EXPERIENCE = 'delete-reaction';

// Query Parameters Actions
export const ADD_QUICK_REACTION_EXPERIENCE = 'add-quick-reaction';

export const COPY_SPACE_PERMISSIONS_EXPERIENCE = 'copy-space-permissions';

export const CREATE_PAGE_EXPERIENCE = 'create-page';
export const CREATE_PAGE_LOAD_DIALOG_EXPERIENCE = 'create-page/load-dialog';
export const CREATE_PAGE_TEMPLATE_VARIABLES_EXPERIENCE = 'create-page/template-variable-input';

export const CREATE_WHITEBOARD_EXPERIENCE = 'create-whiteboard';
export const CREATE_DATABASE_EXPERIENCE = 'create-database';
export const CREATE_EMBED_EXPERIENCE = 'create-embed';
export const CREATE_FOLDER_EXPERIENCE = 'create-folder';

export const EDIT_PAGE_EXPERIENCE = 'edit-page';
export const EDIT_LEGACY_PAGE_EXPERIENCE = 'edit-legacy-page';
export const EDIT_PAGE_EMBED_EXPERIENCE = 'edit-page-embed';
export const EDIT_PAGE_LOADER_EXPERIENCE = 'edit-page/loader'; // loader to determine editor routing (next vs. classic)
export const EDIT_PAGE_LOAD_EXPERIENCE = 'edit-page/load';
export const EDIT_LEGACY_PAGE_LOAD_EXPERIENCE = 'edit-legacy-page/load';
export const EDIT_PAGE_PUBLISH_EXPERIENCE = 'edit-page/publish';
export const EDIT_LEGACY_PAGE_PUBLISH_EXPERIENCE = 'edit-legacy-page/publish';
export const EDIT_PAGE_PUBLISH_AS_BLOG_EXPERIENCE = 'edit-page/publish-as-blog';
export const EDIT_PAGE_CLOSE_EXPERIENCE = 'edit-page/close';
export const EDIT_LEGACY_PAGE_CLOSE_EXPERIENCE = 'edit-legacy-page/close';
export const EDIT_PAGE_REVERT_DRAFT_EXPERIENCE = 'edit-page/revert-draft';

export const LIVE_PAGE_VIEW_EXPERIENCE = 'live-page';
export const LIVE_PAGE_EDIT_EXPERIENCE = 'live-page/edit';
export const LIVE_PAGE_SESSION_EXPERIENCE = 'live-page-session';
export const LIVE_PAGE_PUBLISH_EXPERIENCE = 'live-page/publish';

export const CREATE_AND_EDIT_PAGE_EXPERIENCE = 'create-and-edit-page';

export const TEMPLATE_EDIT_PAGE_EXPERIENCE = 'template-edit-page';
export const TEMPLATE_EDIT_PAGE_LOAD_EXPERIENCE = 'template-edit-page/load';
export const TEMPLATE_EDIT_PAGE_CLOSE_EXPERIENCE = 'template-edit-page/close';
export const TEMPLATE_EDIT_PAGE_PUBLISH_EXPERIENCE = 'template-edit-page/publish';
export const TEMPLATE_EDIT_PAGE_PUBLISH_TEMPLATE_BODY_EXPERIENCE =
	'template-edit-page/publish/template-body';
export const TEMPLATE_EDIT_PAGE_PUBLISH_TEMPLATE_PROPERTIES_EXPERIENCE =
	'template-edit-page/publish/template-properties';

export const IN_EDITOR_TEMPLATES_LOAD_PANEL_EXPERIENCE = 'in-editor-templates-load-panel';
export const IN_EDITOR_TEMPLATES_LOAD_PANEL_TEMPLATE_LIST_EXPERIENCE =
	'in-editor-templates-load-panel/template-list';
export const IN_EDITOR_TEMPLATES_LOAD_PANEL_CATEGORIES_EXPERIENCE =
	'in-editor-templates-load-panel/categories';
export const IN_EDITOR_TEMPLATES_LOAD_PANEL_SPACE_SELECTOR_EXPERIENCE =
	'in-editor-templates-load-panel/space-selector';

export const IN_EDITOR_TEMPLATES_UPDATE_CONTENT_EXPERIENCE = 'in-editor-templates-update-content';
export const IN_EDITOR_TEMPLATES_UPDATE_CONTENT_REFRESH_EDITOR_EXPERIENCE =
	'in-editor-templates-update-content/refresh-editor';

export const TEMPLATE_SUGGESTIONS_LOAD_EXPERIENCE = 'template-suggestions';
export const VIEW_IN_PRODUCT_HELP_EXPERIENCE = 'view-in-product-help';
export const SEARCH_IN_PRODUCT_HELP_EXPERIENCE = 'search-in-product-help';
export const SEARCH_IN_PRODUCT_HELP_LOAD_EXPERIENCE = 'search-in-product-help/load';
export const VIEW_ARTICLE_IN_PRODUCT_HELP_EXPERIENCE = 'view-article-in-product-help';
export const VIEW_ARTICLE_IN_PRODUCT_HELP_LOAD_EXPERIENCE = 'view-article-in-product-help/load';

export const TEMPLATE_GALLERY_EXPERIENCE = 'template-gallery';
export const TEMPLATE_GALLERY_SPACE_SELECTOR_EXPERIENCE = 'template-gallery/space-selector';
export const TEMPLATE_GALLERY_CATEGORIES_EXPERIENCE = 'template-gallery/categories';
export const TEMPLATE_GALLERY_CARDS_EXPERIENCE = 'template-gallery/cards';
export const TEMPLATE_GALLERY_TEMPLATE_DETAIL_EXPERIENCE = 'template-gallery/template-detail';
export const TEMPLATE_GALLERY_TEMPLATE_DETAIL_UPDATE_EXPERIENCE =
	'template-gallery/template-detail-update';

export const TEMPLATE_GALLERY_TEMPLATE_DETAIL_HEADER_EXPERIENCE =
	'template-gallery/template-detail/header';
export const TEMPLATE_PREVIEW_EXPERIENCE = 'template-preview';

export const REFRESH_EDITOR_CONTENT_EXPERIENCE = 'refresh-editor-content';

export const CREATE_CONTENT_FROM_TEMPLATE_EXPERIENCE = 'create-content-from-template';

export const BLUEPRINT_WIZARD_LOAD_DIALOG_EXPERIENCE = 'blueprint-wizard-load-dialog';

export const CONNECT_ADDON_EXPERIENCE = 'connect-addon';

export const QUICK_RELOAD_EXPERIENCE = 'quick-reload';

export const QUICK_RELOAD_V3_EXPERIENCE = 'quick-reload-v3';

export const RESTRICTED_PAGE_EXPERIENCE = 'restricted-page';
export const RESTRICTED_SPACE_FOR_GUEST_EXPERIENCE = 'restricted-space-for-guest';
export const RESTRICTED_SPACE_EXPERIENCE = 'restricted-space';

export const RESTRICTIONS_DIALOG_EXPERIENCE = 'restrictions-dialog';
export const RESTRICTIONS_DIALOG_LOAD_EXPERIENCE = 'restrictions-dialog/load';
export const RESTRICTIONS_DIALOG_APPLY_EXPERIENCE = 'restrictions-dialog/apply';

export const PUBLISH_MODAL_LOAD_RESTRICTIONS_EXPERIENCE = 'publish-modal/load-restrictions';

export const IMPERSONATION_FLAG_EXPERIENCE = 'impersonation-flag';

export const INSPECT_PERMISSIONS_EXPERIENCE = 'inspect-permissions';

export const USER_ACCESS_EXPERIENCE = 'user-access';

// Premium upsells cc-virality
export const UPSELL_EDITION_PREMIUM_EXPERIENCE = 'upsell-edition';
// Non Premium upsells (e.g. Free -> Standard)
export const UPSELL_EDITION_UPFLOW_EXPERIENCE = 'upsell-edition-upflow';

export const ARCHIVE_PAGES_EXPERIENCE = 'archiving/archive-pages';
export const RESTORE_PAGES_EXPERIENCE = 'archiving/restore-pages';
export const SPACE_ARCHIVED_PAGES_EXPERIENCE = 'archiving/view-space-archived-pages';
export const SPACE_ARCHIVED_PAGES_PAGINATE_EXPERIENCE =
	'archiving/view-space-archived-pages/paginate';
export const NOTES_ARCHIVE_PAGES_EXPERIENCE = 'archiving/edit-notes-archive-pages';
export const BULK_NOTES_ARCHIVE_PAGES_EXPERIENCE = 'archiving/bulk-edit-notes-archive-pages';
export const ARCHIVED_PAGE_BANNER_EXPERIENCE = 'archiving/archived-page-banner';

// Automation Discovery Experiment
export const AUTOMATION_DISCOVERY_ADD_LABEL_EXPERIENCE = 'automation-discovery/add-label';
export const AUTOMATION_DISCOVERY_PUBLISH_MEETING_NOTES_EXPERIENCE =
	'automation-discovery/publish-meeting-notes';
export const AUTOMATION_DISCOVERY_ARCHIVE_PAGE_EXPERIENCE = 'automation-discovery/archive-page';
export const AUTOMATION_DISCOVERY_HOVER_OVER_PAGE_TITLE_EXPERIENCE =
	'automation-discovery/hover-over-page-title';
export const AUTOMATION_DISCOVERY_JIRA_LINK = 'automation-discovery/jira-link';
export const AUTOMATION_DISCOVERY_MANUAL_TRIGGER = 'automation-discovery/manual-trigger';
export const AUTOMATION_DISCOVERY_BULK_ARCHIVE = 'automation-discovery/bulk-archive';
export const AUTOMATION_DISCOVERY_PAGE_PUBLIC_LINK = 'automation-discovery/page-public-link';
export const AUTOMATION_MANUAL_TRIGGERS = 'automation-manual-triggers';
export const AUTOMATION_DISCOVERY_WHITEBOARDS = 'automation-discovery/whiteboards';
export const AUTOMATION_DISCOVERY_BULK_CHANGE_PAGE_OWNER =
	'automation-discovery/bulk-change-page-owner';

export const AUTOMATION_SMART_BUTTON_RUN_RULE = 'automation-smart-button-run-rule';
export const AUTOMATION_SMART_BUTTON_CREATE_RULE = 'automation-smart-button-create-rule';
export const AUTOMATION_SMART_BUTTON_EDIT_RULE = 'automation-smart-button-edit-rule';
export const AUTOMATION_SMART_BUTTON_PREVIEW_RULE = 'automation-smart-button-preview-rule';

// Automation new side nav link
export const AUTOMATION_SIDE_NAV_BAR_EXPERIENCE = 'automation-side-nav-bar';

// Automation upsell
export const AUTOMATION_UPSELL_EXPERIENCE = 'automation-upsell';

export const DELETE_PAGE_EXPERIENCE = 'delete-page';
export const BULK_DELETE_PAGES_EXPERIENCE = 'bulk-delete-pages';
export const BULK_CHANGE_OWNER_EXPERIENCE = 'bulk-change-owner';
export const BULK_CHANGE_STATUS_EXPERIENCE = 'bulk-change-status';
export const BULK_CONVERT_PAGE_TO_LIVE_DOC_EXPERIENCE = 'bulk-convert-page-to-live-doc';
export const EDIT_SIDEBAR_EXPERIENCE = 'edit-sidebar';
export const EDIT_SIDEBAR_UPDATE_EXPERIENCE = 'edit-sidebar/update';

export const MANAGE_PAGES_EXPERIENCE = 'manage-pages';
export const MANAGE_PAGES_PAGINATE_EXPERIENCE = 'manage-pages/paginate';

export const CONTENT_MANAGER_EXPERIENCE = 'content-manager';

export const MOVE_PAGE_DIALOG_LOAD_EXPERIENCE = 'move-page-dialog-load';

// Will need to rename this to move-page since we support drafts via attribution now (GENI-2933)
export const MOVE_PAGE_EXPERIENCE = 'move-published-page';

export const MOVE_BLOG_EXPERIENCE = 'move-blog';

// NOTE: Not-found experience can be triggered both by legacy NotFound component
// and one in the next package. The next component is currently only used by
// the content history page, and the legacy component comprises all other usages.
// There is another SSR not-found page rendered for some routes that will not trigger
export const NOT_FOUND_EXPERIENCE = 'not-found';

export const VIEW_CONTENT_EXPERIENCE = 'view-content';
export const VIEW_PAGE_EXPERIENCE = 'view-page';
export const VIEW_PAGE_SESSION_EXPERIENCE = 'view-page-session';
export const SPACE_OVERVIEW_EXPERIENCE = 'space-overview';

export const BREADCRUMBS_EXPERIENCE = 'breadcrumbs';
export const BREADCRUMBS_DROPDOWN_EXPERIENCE = 'breadcrumbs/dropdown';

export const VIEW_PAGE_TITLE_EXPERIENCE = 'view-page/title';
export const VIEW_PAGE_REACTIONS_EXPERIENCE = 'view-page/reactions';
export const VIEW_PAGE_LABELS_EXPERIENCE = 'view-page/labels';
export const VIEW_PAGE_BYLINE_EXPERIENCE = 'view-page/byline';
export const VIEW_PAGE_BYLINE_FORGE_EXPERIENCE = 'view-page/byline/forge';
export const VIEW_PAGE_COMMENTS_EXPERIENCE = 'view-page/comments';
export const VIEW_PAGE_EDIT_BUTTON_EXPERIENCE = 'view-page/edit';
export const VIEW_PAGE_CONTENT_TOOLS_EXPERIENCE = 'view-page/content-tools';
export const VIEW_PAGE_CONTENT_TOOLS_FORGE_EXPERIENCE = 'view-page/content-tools/forge';
export const VIEW_PAGE_CONTEXT_MENU_EXPERIENCE = 'view-page/context-menu';
export const VIEW_PAGE_CONTEXT_MENU_FORGE_EXPERIENCE = 'view-page/context-menu/forge';
export const VIEW_PAGE_CONTENT_HEADER_EXPERIENCE = 'view-page/content-header';
export const VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE = 'view-page/favorite';
export const VIEW_PAGE_SHARE_BUTTON_EXPERIENCE = 'view-page/share-button';
export const VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE = 'view-page/restrictions-button';
export const VIEW_PAGE_COMMENT_BUTTON_EXPERIENCE = 'view-page/comment-button';
export const VIEW_PAGE_DISCOVER_INLINE_COMMENTS_FEATURE_EXPERIENCE =
	'view-page/discover-inline-comments-feature';
export const VIEW_PAGE_PUBLISH_AS_BLOG_EXPERIENCE = 'view-page/publish-as-blog';

// whiteboard experiences
export const WHITEBOARD_EXPERIENCE = 'whiteboard-page';
export const WHITEBOARD_CONTENT_EXPERIENCE = 'whiteboard-page/content';
export const WHITEBOARD_VERSION_HISTORY_SIDE_PANEL_EXPERIENCE =
	'whiteboard-version-history-side-panel';
export const WHITEBOARD_VERSION_HISTORY_RESTORE_EXPERIENCE = 'whiteboard-restore-version';
export const WHITEBOARD_VERSION_HISTORY_LOAD_RESTORE_MODAL =
	'whiteboard-version-history-restore-modal';

// database experiences
export const DATABASE_EXPERIENCE = 'database-page';
export const DATABASE_CONTENT_EXPERIENCE = 'database-page/content';
export const DATABASE_VERSION_HISTORY_SIDE_PANEL_EXPERIENCE = 'database-version-history-side-panel';
export const DATABASE_VERSION_HISTORY_RESTORE_EXPERIENCE = 'database-restore-version';
export const DATABASE_VERSION_HISTORY_LOAD_RESTORE_MODAL = 'database-version-history-restore-modal';
export const DATABASE_LOAD_HISTORICAL_VERSION = 'database-load-historical-version';

// embed experiences
export const EMBED_EXPERIENCE = 'embed-page';
export const EMBED_CONTENT_EXPERIENCE = 'embed-page/content';
export const EDIT_EMBED_EXPERIENCE = 'edit-embed';

// folder experiences
export const FOLDER_EXPERIENCE = 'folder-page';
export const FOLDER_CONTENT_EXPERIENCE = 'folder-page/content';

// content types experiences
export const CONTENT_TYPES_HEADER_EXPERIENCE = 'content-types-header';
export const CONTENT_TYPES_HEADER_WATCH_EXPERIENCE = 'content-types-header/watch';
export const CONTENT_CREATION_PAYWALL_EXPERIENCE = 'content-creation-paywall';
export const RECORD_LOOM_ENTRY_POINT = 'record-loom-entry-point';
export const LOOM_PAGE_HEADER_CONTENT_INSERTION = 'loom-page-header-content-insertion';
export const LOOM_CROSS_SELL_EXPERIENCE = 'loom-cross-sell';

export const EDIT_PAGE_LABELS_EXPERIENCE = 'edit-page/labels';
export const LABELS_ADD_LABEL_EXPERIENCE = 'labels/add-label';
export const LABELS_CREATE_LABEL_EXPERIENCE = 'labels/create-label';
export const LABELS_DELETE_LABEL_EXPERIENCE = 'labels/delete-label';
export const LABELS_SEARCH_LABEL_EXPERIENCE = 'labels/search-label';
export const LABELS_RECOMMENDED_EXPERIENCE = 'labels/recommended';

export const TEMPLATE_EDIT_PAGE_LABELS_EXPERIENCE = 'template-edit-page/labels';
export const TEMPLATE_EDIT_PAGE_LABELS_ADD_LABEL_EXPERIENCE = 'template-edit-page/labels/add-label';
export const TEMPLATE_EDIT_PAGE_LABELS_CREATE_LABEL_EXPERIENCE =
	'template-edit-page/labels/create-label';
export const TEMPLATE_EDIT_PAGE_LABELS_DELETE_LABEL_EXPERIENCE =
	'template-edit-page/labels/delete-label';
export const TEMPLATE_EDIT_PAGE_LABELS_SEARCH_LABEL_EXPERIENCE =
	'template-edit-page/labels/search-label';

export const HEADER_ITEMS_GROUP_LOAD_EXPERIENCE = 'header-items/group-load';

export const HOME_EXPERIENCE = 'home';
export const HOME_SPACES_EXPERIENCE = 'home/spaces';
export const HOME_RECENT_PAGES_EXPERIENCE = 'home/recent-pages';
export const HOME_RIGHT_PANEL_EXPERIENCE = 'home/right-panel';
export const HOME_RIGHT_PANEL_ACTIVITY_EXPERIENCE = 'home/right-panel/activity';
export const HOME_RIGHT_PANEL_WELCOME_MESSAGE_EXPERIENCE = 'home/right-panel/welcome-message';
export const HOME_RIGHT_PANEL_CALENDARS_EXPERIENCE = 'home/right-panel/calendars';
export const HOME_RIGHT_PANEL_APPS_EXPERIENCE = 'home/right-panel/apps';
export const HOME_RIGHT_PANEL_FORGE_EXPERIENCE = 'home/right-panel/forge';
// Exp TLBZ-2 Reccos in Connie Home
export const RECOMMENDATIONS_IN_HOME_EXPERIENCE = 'home/right-panel/recommendations';
export const RECOMMENDATIONS_IN_HOME_CONTEXT = 'confluence';

export const FEED_EXPERIENCE = 'feed';

// Home Experiences
export const HOME_OVERVIEW_EXPERIENCE = 'home/overview';
export const HOME_OVERVIEW_RECENT_EXPERIENCE = 'home/overview/recent';
export const HOME_OVERVIEW_RECENT_PAGINATE_EXPERIENCE = 'home/overview/recent/paginate';

export const HOME_RECENT_EXPERIENCE = 'home/recent';
export const HOME_STARRED_EXPERIENCE = 'home/starred';
export const HOME_WATCHING_EXPERIENCE = 'home/watching';
export const HOME_DRAFTS_EXPERIENCE = 'home/drafts';

// Search Experiences
export const ADVANCED_SEARCH_EXPERIENCE = 'advanced-search';
export const ADVANCED_SEARCH_SPACE_FILTER_EXPERIENCE = 'advanced-search/space-filter';
export const ADVANCED_SEARCH_CONTRIBUTOR_FILTER_EXPERIENCE = 'advanced-search/contributor-filter';
export const ADVANCED_SEARCH_SMART_CONTRIBUTOR_FILTER_EXPERIENCE =
	'advanced-search/smart-contributor-filter';
export const QUICK_SEARCH_PRE_QUERY = 'quick-search/pre-query';

export const RECENT_DROPDOWN_VISITED_EXPERIENCE = 'recent-dropdown/visited';
export const RECENT_DROPDOWN_CREATED_BY_ME_EXPERIENCE = 'recent-dropdown/created-by-me';
export const RECENT_DROPDOWN_WORKED_ON_EXPERIENCE = 'recent-dropdown/worked-on';
export const RECENT_DROPDOWN_DRAFTS_EXPERIENCE = 'recent-dropdown/drafts';
export const RECENT_DROPDOWN_STARRED_EXPERIENCE = 'recent-dropdown/starred';

// Page Tree
export const PAGE_TREE_EXPERIENCE = 'page-tree';
export const PAGE_TREE_DND_EXPERIENCE = 'page-tree/dnd';
export const PAGE_TREE_QUICK_ACTIONS_EXPERIENCE = 'page-tree/quick-actions';
export const PAGE_TREE_RENAME_EXPERIENCE = 'page-tree/rename';

// Blog Tree
export const BLOG_TREE_EXPERIENCE = 'blog-tree';
export const BLOG_TREE_PAGINATION_EXPERIENCE = 'blog-tree/pagination';

export const ACTIVITY_TAILORED_EXPERIENCE = 'activity/tailored';
export const ACTIVITY_CREATE_SPACE_BUTTON_EXPERIENCE = 'activity/create-space-button';
export const ACTIVITY_RIGHT_SIDEBAR_EXPERIENCE = 'activity/right-sidebar';

export const FEED_MIGRATION_ACTIVITY_POPULAR_EXPERIENCE = 'feed-migration/activity/popular';
export const FEED_MIGRATION_ACTIVITY_ALL_UPDATES_EXPERIENCE = 'feed-migration/activity/all-updates';

export const NAV2_ADMIN_FORGE_EXPERIENCE = 'navigation/nav-2-admin-navigation/forge';

export const APP_NAV_GLOBAL_EXPERIENCE = 'navigation/app-nav-global-navigation';
export const APP_NAV_CONTAINER_EXPERIENCE = 'navigation/app-nav-container-navigation';
export const APP_NAV_ADMIN_EXPERIENCE = 'navigation/app-nav-admin-navigation';
export const APP_NAV_SPACES_DROPDOWN_EXPERIENCE = 'navigation/app-nav-spaces-dropdown';
export const APP_NAV_PAGES_DROPDOWN_EXPERIENCE = 'navigation/app-nav-pages-dropdown';
export const APP_NAV_APPS_DROPDOWN_EXPERIENCE = 'navigation/app-nav-apps-dropdown';
export const APP_NAV_NOTIFICATIONS_DROPDOWN_EXPERIENCE =
	'navigation/app-nav-notifications-dropdown';
export const APP_NAV_PROFILE_DROPDOWN_EXPERIENCE = 'navigation/app-nav-profile-dropdown';

export const SPACE_DIRECTORY_EXPERIENCE = 'space-directory';
export const SPACE_DIRECTORY_YOUR_SPACES_EXPERIENCE = 'space-directory/your-spaces';
export const SPACE_DIRECTORY_FILTER_EXPERIENCE = 'space-directory/filter';
export const SPACE_DIRECTORY_PAGINATE_EXPERIENCE = 'space-directory/paginate';

export const CREATE_SPACE_SUBMIT_EXPERIENCE = 'create-space/submit';
export const CREATE_SPACE_VALIDATE_EXPERIENCE = 'create-space/validate';

// Land and Onboarding
export const ONBOARDING_START_EXPERIENCE = 'onboarding/start';
export const ONBOARDING_INVITE_USERS_EXPERIENCE = 'onboarding/invite-users';
export const ONBOARDING_REMOVE_INVITE_USERS_EXPERIENCE = 'onboarding/remove-invite-users';
export const ONBOARDING_CREATE_A_SPACE_EXPERIENCE = 'onboarding/create-a-space';
export const ONBOARDING_CHOOSE_A_SPACE_EXPERIENCE = 'onboarding/choose-a-space';
export const ONBOARDING_END_EXPERIENCE = 'onboarding/end';
export const ONBOARDING_CHOOSE_A_SPACE_CARD_EXPERIENCE = 'onboarding/choose-a-space-card';
export const ONBOARDING_FOLLOW_SPACES_EXPERIENCE = 'onboarding/follow-spaces';
export const ONBOARDING_SHARED_LINK_SPOTLIGHT_EXPERIENCE = 'onboarding/shared-link-spotlight';
export const ONBOARDING_NUDGE_EXPERIENCE = 'onboarding/nudge';
export const ONBOARDING_ACCOUNT_SETUP_MODAL_EXPERIENCE = 'onboarding-account-setup-modal';
export const ONBOARDING_QUICKSTART_EXPERIENCE = 'onboarding-quickstart';
export const ONBOARDING_QUICKSTART_INVITE_TOUR_EXPERIENCE = 'onboarding-quickstart/invite-tour';
export const ONBOARDING_NTH_USER_QUICKSTART_HOME_TOUR_EXPERIENCE =
	'onboarding-nth-user-quick-start/home-tour';
export const ONBOARDING_NTH_USER_QUICKSTART_SPACE_TOUR_EXPERIENCE =
	'onboarding-nth-user-quick-start/space-tour';
export const ONBOARDING_JSW_XFLOW_ADMIN_SWITCHER_TOUR_EXPERIENCE =
	'onboarding-jsw-xflow/admin-switcher-tour';
export const ONBOARDING_JSW_XFLOW_NTH_SWITCHER_TOUR_EXPERIENCE =
	'onboarding-jsw-xflow/nth-switcher-tour';
export const ONBOARDING_JSW_XFLOW_INVITE_TEAMMATE_TASK_EXPERIENCE =
	'onboarding-jsw-xflow/invite-teammate-task';
export const ONBOARDING_EDITOR_DRAFT_BANNER_EXPERIENCE = 'onboarding-editor/draft-banner';
export const ONBOARDING_LAND_IN_EDITOR_BANNER_EXPERIENCE =
	'onboarding-editor/land-in-editor-banner';
export const ONBOARDING_EDITOR_QUICKSTART_TUTORIAL_TASK_EXPERIENCE =
	'onboarding-editor/quickstart-tutorial-task';
export const ONBOARDING_EDITOR_PUBLISH_MODAL_PROMPT_EXPERIENCE =
	'onboarding-editor/publish-modal-prompt';
export const ONBOARDING_EDITOR_PUBLISH_SUCCESS_FLAG_EXPERIENCE =
	'onboarding-editor/publish-success-flag';
export const ONBOARDING_EDITOR_RECENT_DRAFT_SPOTLIGHT_EXPERIENCE =
	'onboarding-editor/recent-draft-spotlight';
export const ONBOADING_LABELS_QUERY_EXPERIENCE = 'onboarding/query/labels';
export const ONBOARDING_GRACE_PERIOD_BANNER_EXPERIENCE = 'onboarding/grace-period-banner';
export const ONBOARDING_STORAGE_ENFORCEMENT_BANNER_EXPERIENCE =
	'onboarding/storage-enforcement-banner';
export const ONBOARDING_STORAGE_ENFORCEMENT_BANNER_DISMISS_EXPERIENCE =
	'onboarding/storage-enforcement-banner/dismiss';
export const ONBOARDING_STORAGE_ENFORCEMENT_FLAGS_EXPERIENCE =
	'onboarding/storage-enforcement-flags';
export const ONBOARDING_STORAGE_ENFORCEMENT_BLOCK_CREATE_MODAL =
	'onboarding/storage-enforcement/block-create-modal';
export const ONBOARDING_TEMPLATE_COLLECTIONS_EXPERIENCE = 'onboarding/home/templates-collection';
export const ONBOARDING_TEMPLATE_COLLECTIONS_CREATE_TEMPLATE_EXPERIENCE =
	'onboarding/home/templates-collection/create-template';
export const ONBOARDING_IBO_NEW_HOME_RECENT_PAGE_EXPERIENCE = 'onboarding/ibo/new-home/recent-page';
export const ONBOARDING_IBO_NEW_HOME_SPACES_EXPERIENCE = 'onboarding/ibo/new-home/spaces';
export const ONBOARDING_IBO_NEW_HOME_EXPERIENCE = 'onboarding/ibo/new-home';
export const ONBOARDING_NTH_USER_SETUP_MODAL_EXPERIENCE = 'onboarding/nth-user-setup-modal';
export const ONBOARDING_TRACKER_EXPERIENCE = 'onboarding/onboarding-tracker';
export const ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT =
	'onboarding/onboarding-tracker/organize-space/spotlight';
export const ONBOARDING_TRACKER_ADD_CONTENT_SPOTLIGHT =
	'onboarding/onboarding-tracker/add-content/spotlight';
export const ONBOARDING_TRACKER_INVITE_PEOPLE_SPOTLIGHT =
	'onboarding/onboarding-tracker/invite-people/spotlight';
export const ONBOARDING_GLOBAL_IMPORT_PAGE_CREATE =
	'onboarding/onboarding-surface-import/global-import/create-page';
export const ONBOARDING_CONFLUENCE_RECAP_MODAL_EXPERIENCE = 'onboarding/confluence-recap-modal';
export const ONBOARDING_HOME_ONBOARDING_TAKEOVER_V2 =
	'onboarding/confluence-home-onboarding-takeover-v2';
export const ONBOARDING_NTH_USER_HOME_ONBOARDING_TAKEOVER =
	'onboarding/nth-user-confluence-home-onboarding-takeover';
export const ONBOARDING_HOME_ONBOARDING_TAKEOVER_V2_HOME_NUDGE =
	'onboarding/confluence-home-onboarding-takeover-v2/home-nudge';
export const ONBOARDING_PERSONALIZED_TEMPLATES_V2 =
	'onboarding/confluence-personalized-templates-v2';

export const CONTENT_ACTION_APPS_FORGE_EXPERIENCE = 'content-action-apps/forge';
export const BYLINE_APPS_FORGE_EXPERIENCE = 'byline-apps/forge';
export const CONTEXT_MENU_FORGE_EXPERIENCE = 'context-menu-apps/forge';

export const SPACE_PAGE_APPS_FORGE_EXPERIENCE = 'space-page-apps/forge';
export const SPACE_SETTINGS_INTEGRATIONS_FORGE_EXPERIENCE = 'space-settings/integrations/forge';
export const SPACE_SETTINGS_V2_INTEGRATIONS_FORGE_EXPERIENCE =
	'space-settings-v2/integrations/forge';
export const SPACE_SETTINGS_LANDING_PAGE_EXPERIENCE = 'space-settings-v2/landing-page';

export const GLOBAL_PAGE_APPS_FORGE_EXPERIENCE = 'global-page-apps/forge';

export const SITE_PERMISSION_SPACE_ACCESS_EXPERIENCE = 'site-permission/space-access';

export const SPACE_PAGES_EXPERIENCE = 'space-pages';
export const SPACE_PAGES_FILTER_EXPERIENCE = 'space-pages/filter';
export const SPACE_PAGES_PAGINATE_EXPERIENCE = 'space-pages/paginate';

export const SPACE_BLOGS_EXPERIENCE = 'space-blogs';
export const SPACE_BLOGS_PAGINATE_EXPERIENCE = 'space-blogs/paginate';

export const SPACE_VIEWS_INITIAL_EXPERIENCE = 'space-views/initial';
export const SPACE_VIEWS_CHANGE_EXPERIENCE = 'space-views/change';
export const SPACE_VIEWS_PAGINATE_EXPERIENCE = 'space-views/paginate';
export const SPACE_VIEWS_HOVER_PAGE_CARD_EXPERIENCE = 'space-views/hover-page-card';

export const SITE_PERMISSION_REMOVE_SPACE_ACCESS_EXPERIENCE =
	'site-permissions/space-access/remove';
export const SITE_PERMISSION_ADD_SPACE_ACCESS_EXPERIENCE = 'site-permissions/space-access/add';
export const SITE_PERMISSION_CHANGE_GUEST_SPACE_ACCESS_EXPERIENCE =
	'site-permissions/space-access/guest/change';

// Analytics
export const ANALYTICS_BYLINE_EXPERIENCE = 'insights/page-byline';
export const ANALYTICS_DIALOG_EXPERIENCE = 'insights/page-dialog';
export const ANALYTICS_DIALOG_UPSELL_EXPERIENCE = 'insights/page-dialog-upsell';

export const PAGE_ANALYTICS_EXPERIENCE = 'insights/page-analytics';
export const PAGE_ANALYTICS_VIEW_CHART_EXPERIENCE = 'insights/page-analytics/view-chart';
export const PAGE_ANALYTICS_TOTAL_VIEWS_CARD_EXPERIENCE =
	'insights/page-analytics/total-views-card';
export const PAGE_ANALYTICS_UNIQUE_VIEWERS_CARD_EXPERIENCE =
	'insights/page-analytics/unique-viewers-card';
export const PAGE_ANALYTICS_MENTIONED_USERS_CARD_EXPERIENCE =
	'insights/page-analytics/mentioned-users-card';
export const PAGE_ANALYTICS_VIEWERS_TABLE_EXPERIENCE = 'insights/page-analytics/viewers-table';
export const PAGE_ANALYTICS_MENTIONED_USERS_REMINDER_EXPERIENCE =
	'insights/page-analytics/mentioned-users-reminder';
export const PAGE_ANALYTICS_MENTIONED_USERS_TABLE_EXPERIENCE =
	'insights/page-analytics/mentioned-users-table';
export const PAGE_ANALYTICS_LINKS_INBOUND_TOTAL_CLICKS_CARD_EXPERIENCE =
	'insights/page-analytics/links-inbound-total-clicks-card';
export const PAGE_ANALYTICS_LINKS_INBOUND_CTR_CARD_EXPERIENCE =
	'insights/page-analytics/links-inbound-ctr-card';
export const PAGE_ANALYTICS_LINKS_INBOUND_TABLE_EXPERIENCE =
	'insights/page-analytics/links-inbound-table';
export const PAGE_ANALYTICS_LINKS_OUTBOUND_TOTAL_CLICKS_CARD_EXPERIENCE =
	'insights/page-analytics/links-outbound-total-clicks-card';
export const PAGE_ANALYTICS_LINKS_OUTBOUND_CTR_CARD_EXPERIENCE =
	'insights/page-analytics/links-outbound-ctr-card';
export const PAGE_ANALYTICS_LINKS_OUTBOUND_TABLE_EXPERIENCE =
	'insights/page-analytics/links-outbound-table';

export const SPACE_ANALYTICS_EXPERIENCE = 'insights/space-analytics';
export const SPACE_ANALYTICS_OVERVIEW_CHART_EXPERIENCE = 'insights/space-analytics/overview-chart';
export const SPACE_ANALYTICS_POPULAR_CONTENT_CARD_EXPERIENCE =
	'insights/space-analytics/popular-content-card';
export const SPACE_ANALYTICS_ACTIVE_READERS_CARD_EXPERIENCE =
	'insights/space-analytics/active-readers-card';
export const SPACE_ANALYTICS_ACTIVE_CONTRIBUTORS_CARD_EXPERIENCE =
	'insights/space-analytics/active-contributors-card';
export const SPACE_ANALYTICS_CONTENT_ACTIVITY_TABLE_EXPERIENCE =
	'insights/space-analytics/content-activity-table';
export const SPACE_ANALYTICS_USER_ACTIVITY_TABLE_EXPERIENCE =
	'insights/space-analytics/user-activity-table';
export const SPACE_ANALYTICS_ASYNC_EXPORT_EXPERIENCE = 'insights/space-analytics/async-export';
export const SPACE_ANALYTICS_PERMISSIONS_LOAD_EXPERIENCE =
	'insights/space-analytics/permissions/load';
export const SPACE_ANALYTICS_PERMISSIONS_UPDATE_EXPERIENCE =
	'insights/space-analytics/permissions/update';

export const SITE_ANALYTICS_EXPERIENCE = 'insights/site-analytics';
export const SITE_ANALYTICS_OVERVIEW_CHART_EXPERIENCE = 'insights/site-analytics/overview-chart';
export const SITE_ANALYTICS_POPULAR_SPACES_CARD_EXPERIENCE =
	'insights/site-analytics/popular-spaces-card';
export const SITE_ANALYTICS_ACTIVE_READERS_CARD_EXPERIENCE =
	'insights/site-analytics/active-readers-card';
export const SITE_ANALYTICS_POPULAR_SEARCHES_CARD_EXPERIENCE =
	'insights/site-analytics/popular-searches-card';
export const SITE_ANALYTICS_SPACES_TABLE_EXPERIENCE = 'insights/site-analytics/spaces-table';
export const SITE_ANALYTICS_USERS_TABLE_EXPERIENCE = 'insights/site-analytics/users-table';
export const SITE_ANALYTICS_SEARCH_TABLE_EXPERIENCE = 'insights/site-analytics/search-table';
export const SITE_ANALYTICS_SEARCH_PANEL_EXPERIENCE = 'insights/site-analytics/search-panel';
export const SITE_ANALYTICS_SEARCH_PANEL_GRAPH_EXPERIENCE =
	'insights/site-analytics/search-panel/graph';
export const SITE_ANALYTICS_SEARCH_PANEL_CONTENT_LIST_EXPERIENCE =
	'insights/site-analytics/search-panel/content-list';
export const SITE_ANALYTICS_SEARCH_PANEL_SPACES_LIST_EXPERIENCE =
	'insights/site-analytics/search-panel/spaces-list';
export const SITE_ANALYTICS_PERMISSIONS_LOAD_EXPERIENCE =
	'insights/site-analytics/permissions/load';
export const SITE_ANALYTICS_PERMISSIONS_UPDATE_EXPERIENCE =
	'insights/site-analytics/permissions/update';

export const BULK_SPACE_PERMISSIONS_EXPERIENCE = 'bulk-space-permissions';

export const GLOBAL_PERMS_EXPERIENCE = 'global-perms';
export const EXPAND_GROUP_EXPERIENCE = 'expand-group';
export const GROUP_MEMBERS_LOAD_MORE_EXPERIENCE = 'group-members-load-more';

export const VIEW_SPACE_GUEST_LIST_EXPERIENCE = 'view-space-guest-list';

export const TEAM_CALENDAR_EXPERIENCE = 'team-calendar';
export const TEAM_CALENDAR_UPSELL_EXPERIENCE = 'team-calendar-upsell';
export const TEAM_CALENDARS_ADD_NEW_CALENDAR_EXPERIENCE = 'team-calendars/calendar/add';
export const TEAM_CALENDARS_ADD_EXISTING_CALENDAR_EXPERIENCE =
	'team-calendars/calendar/add-existing';
export const TEAM_CALENDARS_DELETE_CALENDAR_EXPERIENCE = 'team-calendars/calendar/delete';
export const TEAM_CALENDARS_REMOVE_CALENDAR_EXPERIENCE = 'team-calendars/calendar/remove';
export const TEAM_CALENDARS_SUBSCRIBE_CALENDAR_EXPERIENCE = 'team-calendars/calendar/subscribe';
export const TEAM_CALENDARS_UPDATE_CALENDAR_EXPERIENCE = 'team-calendars/calendar/update';
export const TEAM_CALENDARS_IMPORT_CALENDAR_EXPERIENCE = 'team-calendars/calendar/import';
export const TEAM_CALENDARS_ADD_EVENT_TYPE_EXPERIENCE = 'team-calendars/event-type/add';
export const TEAM_CALENDARS_DELETE_EVENT_TYPE_EXPERIENCE = 'team-calendars/event-type/delete';
export const TEAM_CALENDARS_UPDATE_EVENT_TYPE_EXPERIENCE = 'team-calendars/event-type/update';
export const TEAM_CALENDARS_ADD_EVENT_EXPERIENCE = 'team-calendars/event/add';
export const TEAM_CALENDARS_JIRA_CALENDAR_EXPERIENCE = 'team-calendars/event/jira-calendar';
export const TEAM_CALENDARS_UPDATE_EVENT_EXPERIENCE = 'team-calendars/event/update';
export const TEAM_CALENDARS_DELETE_EVENT_EXPERIENCE = 'team-calendars/event/delete';
export const TEAM_CALENDARS_UPDATE_RESTRICTIONS_EXPERIENCE = 'team-calendars/restrictions/update';

export const EDIT_SPACE_LOGO_RESTORE_EXPERIENCE = 'edit-space-logo/restore';
export const EDIT_SPACE_LOGO_SAVE_EXPERIENCE = 'edit-space-logo/save';
export const EDIT_SPACE_LOGO_DETAILS_LOAD_EXPERIENCE = 'edit-space-logo/details-load';

export const COVER_PICTURE_UNSPLASH_UPLOAD_EXPERIENCE = 'cover-picture/unsplash-upload';
export const COVER_PICTURE_MEDIA_UPLOAD_EXPERIENCE = 'cover-picture/media-upload';
export const COVER_PICTURE_REMOVE_EXPERIENCE = 'cover-picture/remove';

export const VIEW_APP_SUGGESTIONS_EXPERIENCE = 'view-app-suggestions';

export const CONDITIONAL_PUBLISH_DIALOG_EXPERIENCE = 'conditional_publish_dialog';

export const EXTENSION_POINT_BYLINE_EXPERIENCE = 'extension_point_byline';
export const EXTENSION_POINT_SPACE_BUTTONS_EXPERIENCE = 'extension_point_space_buttons';
export const EXTENSION_POINT_HOME_FOOTER_EXPERIENCE = 'extension_point_home_footer';
export const EXTENSION_POINT_HOME_HEADER_EXPERIENCE = 'extension_point_home_header';
export const EXTENSION_POINT_EDITOR_SAVEBAR_EXPERIENCE = 'extension_point_editor_savebar';
export const EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE =
	'extension_point_page_metadata_banner';
export const EXTENSION_POINT_SPACE_SETTINGS_TABS_EXPERIENCE = 'extension_point_space_settings_tabs';

export const CORE_INVITES_EXPERIENCE = 'core-invites';

export const CUSTOM_CONTENT_PAGE_ALL_EXPERIENCE = 'custom-content-page-all';
export const CUSTOM_CONTENT_SPACE_LIST_VIEW_EXPERIENCE = 'custom-content-space-list-view';
export const CUSTOM_CONTENT_PAGE_LIST_VIEW_EXPERIENCE = 'custom-content-page-list-view';
export const CUSTOM_CONTENT_VIEW_EXPERIENCE = 'custom-content-view';
export const CONNECTIONS_PANEL_VIEW_EXPERIENCE = 'connections-panel-view';

export const EOP_RECS_EXPERIENCE = 'end-of-page-recommendations';

export const NOTIFY_ON_FIRST_VIEW_EXPERIENCE = 'notify-on-first-view';

export const PRESENTER_MODE_VIEW_EXPERIENCE = 'presenter-mode/view';
export const PRESENTER_MODE_FULLSCREEN_API_EXPERIENCE = 'presenter-mode/full-screen-api';

// Public Link Experiences
// Current share
export const VIEW_PUBLIC_LINK_SITE_SETTINGS_EXPERIENCE = 'view-public-link-site-settings';
export const VIEW_PUBLIC_LINK_SPACES_TABLE_EXPERIENCE = 'view-public-link-spaces-table';
export const VIEW_PUBLIC_LINKS_PAGE_TABLE_EXPERIENCE = 'view-public-links-page-table';
export const PUBLIC_LINKS_SPACES_TABLE_ACTION_EXPERIENCE = 'public-links-spaces-table-action';
export const PUBLIC_LINK_SITE_TOGGLE_CHANGE_EXPERIENCE = 'public-link-site-toggle-change';
export const VIEW_PUBLIC_LINKS_SPACE_SETTINGS_EXPERIENCE = 'view-public-links-space-settings';
export const PUBLIC_LINKS_SPACE_TOGGLE_CHANGE_EXPERIENCE = 'public-links-space-toggle-change';
export const VIEW_PUBLIC_LINKS_DEFAULT_SPACE_SETTINGS_EXPERIENCE =
	'view-public-links-default-space-settings';
export const PUBLIC_LINKS_DEFAULT_SPACE_TOGGLE_CHANGE_EXPERIENCE =
	'public-links-default-space-toggle-change';
export const VIEW_PUBLIC_LINK_SHARED_PAGE_EXPERIENCE = 'view-public-link-shared-page';
export const PUBLIC_LINK_RENDERED_EXPERIENCE = 'public-link-rendered';
export const PUBLIC_LINK_TOGGLE_CHANGE_EXPERIENCE = 'public-link-toggle-change';
export const PUBLIC_LINK_DISABLE_FROM_RESTRICTIONS_EXPERIENCE =
	'public-link-disable-from-restrictions';
export const PUBLIC_LINK_PAGE_TABLE_ACTION_EXPERIENCE = 'public-link-page-table-action';
export const EXTERNAL_SHARE_EXPERIENCE = 'external-share';
export const COPY_CONTENT_LINK_EXPERIENCE = 'copy-content-link';
export const CONTENT_HISTORY_EXPERIENCE = 'content-history';

// Tasks Home
export const VIEW_TASKS_EXPERIENCE = 'view-tasks';
export const SET_TASK_COMPLETE_EXPERIENCE = 'set-task-complete';

// Content Ownership
export const CHANGE_PAGE_OWNER_EXPERIENCE = 'change-page-owner';
export const CONTENT_OWNERSHIP_DIALOG_EXPERIENCE = 'content-ownership/dialog';

// Admin Announcement Banner
export const ADMIN_ANNOUNCEMENT_BANNER_EXPERIENCE = 'admin-announcement-banner';
export const ADMIN_ANNOUNCEMENT_BANNER_DISMISS_EXPERIENCE = 'admin-announcement-banner-dismissed';
export const ADMIN_ANNOUNCEMENT_BANNER_SETTINGS_EXPERIENCE = 'admin-announcement-banner-settings';
export const ADMIN_ANNOUNCEMENT_BANNER_PUBLISH_EXPERIENCE = 'admin-announcement-banner-publish';
export const ADMIN_ANNOUNCEMENT_BANNER_TURN_OFF_EXPERIENCE = 'admin-announcement-banner-turn-off';
export const ADMIN_ANNOUNCEMENT_BANNER_TABLE_EXPERIENCE = 'admin-announcement-banner-table';

// Space Manager
export const SPACE_MANAGER_EXPERIENCE = 'space-manager';
export const SPACE_MANAGER_QUICK_ACTION_ITEM_EXPERIENCE = 'space-manager-quick-action-item';

// Company Hub General Settings
export const COMPANY_HUB_GENERAL_SETTINGS_EXPERIENCE = 'company-hub-general-settings';
export const COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_EXPERIENCE =
	'company-hub-general-settings-your-hub';
export const COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_ACTIONS_EXPERIENCE =
	'company-hub-general-settings-your-hub-actions';
export const COMPANY_HUB_GENERAL_SETTINGS_HUB_RECENT_ACTIVITY_EXPERIENCE =
	'company-hub-general-settings-recent-activity';
export const COMPANY_HUB_GENERAL_SETTINGS_HUB_VIEWS_EXPERIENCE =
	'company-hub-general-settings-hub-views';
export const COMPANY_HUB_GENERAL_SETTINGS_MOST_CLICKS_EXPERIENCE =
	'company-hub-general-settings-most-clicks';

// Admin Center Page
export const ADMIN_CENTER_EXPERIENCE = 'admin-center';
export const ADMIN_CENTER_CONFLUENCE_ADMINS_EXPERIENCE = 'admin-center-confluence-admins';
export const ADMIN_CENTER_SEARCH_COUNT_EXPERIENCE = 'admin-center-search-count';
export const ADMIN_CENTER_SEARCH_CTR_EXPERIENCE = 'admin-center-search-ctr';
export const ADMIN_CENTER_TOP_SEARCHES_EXPERIENCE = 'admin-center-top-searches';
export const ADMIN_CENTER_TOP_UNSUCCESSFUL_SEARCHES_EXPERIENCE =
	'admin-center-top-unsuccessful-searches';
export const ADMIN_CENTER_EXTERNAL_ACCESS_EXPERIENCE = 'admin-center-external-access';
export const ADMIN_CENTER_BETA_FEATURES_EXPERIENCE = 'admin-center-beta-features';
export const ADMIN_CENTER_TOTAL_SPACES_EXPERIENCE = 'admin-center-total-spaces';
export const ADMIN_CENTER_TOTAL_CONTENT_EXPERIENCE = 'admin-center-total-content';
export const ADMIN_CENTER_GROUPED_SUMMARY_EXPERIENCE = 'admin-center-grouped-summary-section';
export const ADMIN_CENTER_INACTIVE_PAGES_EXPERIENCE = 'admin-center-inactive-pages';
export const ADMIN_CENTER_TOP_INACTIVE_PAGES_EXPERIENCE = 'admin-center-top-inactive-pages';
export const ADMIN_CENTER_DEACTIVATED_OWNER_PAGES_EXPERIENCE =
	'admin-center-deactivated-owner-pages';
export const ADMIN_CENTER_TOP_DEACTIVATED_OWNER_PAGES_EXPERIENCE =
	'admin-center-top-deactivated-owner-pages';
export const ADMIN_CENTER_CONTENT_CREATED_EXPERIENCE = 'admin-center-content-created';
export const ADMIN_CENTER_CONTENT_UPDATED_EXPERIENCE = 'admin-center-content-updated';
export const ADMIN_CENTER_CONTENT_VIEWS_EXPERIENCE = 'admin-center-content-views';
export const ADMIN_CENTER_TOP_CONTENT_VIEWS_EXPERIENCE = 'admin-center-top-content-views';

// Admin Center Page - Custom Overview section
export const ADMIN_CENTER_OVERVIEW_UPDATE_EXPERIENCE =
	'admin-center-grouped-summary-section-v2-update';
export const ADMIN_CENTER_OVERVIEW_EXPERIENCE = 'admin-center-grouped-summary-section-v2';
export const ADMIN_CENTER_OVERVIEW_SEARCH_COUNT_EXPERIENCE = 'admin-center-search-count-summary';
export const ADMIN_CENTER_OVERVIEW_SEARCH_CTR_EXPERIENCE = 'admin-center-search-ctr-summary';
export const ADMIN_CENTER_OVERVIEW_TOTAL_SPACES_EXPERIENCE = 'admin-center-total-spaces-summary';
export const ADMIN_CENTER_OVERVIEW_TOTAL_CONTENT_EXPERIENCE = 'admin-center-total-content-summary';
export const ADMIN_CENTER_OVERVIEW_INACTIVE_PAGES_NUM_EXPERIENCE =
	'admin-center-inactive-pages-num-summary';
export const ADMIN_CENTER_OVERVIEW_INACTIVE_PAGES_PERCENT_EXPERIENCE =
	'admin-center-inactive-pages-percent-summary';
export const ADMIN_CENTER_OVERVIEW_DEACTIVATED_OWNER_PAGES_NUM_EXPERIENCE =
	'admin-center-deactivated-owner-pages-num-summary';
export const ADMIN_CENTER_OVERVIEW_DEACTIVATED_OWNER_PAGES_PERCENT_EXPERIENCE =
	'admin-center-deactivated-owner-pages-percent-summary';
export const ADMIN_CENTER_OVERVIEW_CONTENT_CREATED_EXPERIENCE =
	'admin-center-content-created-summary';
export const ADMIN_CENTER_OVERVIEW_CONTENT_UPDATED_EXPERIENCE =
	'admin-center-content-updated-summary';
export const ADMIN_CENTER_OVERVIEW_CONTENT_VIEWS_EXPERIENCE = 'admin-center-content-views-summary';
export const ADMIN_CENTER_OVERVIEW_PUBLIC_LINK_VIEWS_EXPERIENCE =
	'admin-center-public-link-views-summary';
export const ADMIN_CENTER_OVERVIEW_PUBLIC_LINKS_EXPERIENCE = 'admin-center-public-links-summary';

//Premium trial insights
export const ADMIN_CENTER_PREMIUM_TRIAL_INSIGHTS_EXPERIENCE = 'admin-center-premium-trial-insights';

// Admin Center Page - Suggestions section
export const ADMIN_CENTER_SUGGESTIONS_EXPERIENCE = 'admin-center-suggestions';
// Metric suggestions
export const ADMIN_CENTER_SUGGESTIONS_UNASSIGNED_GUEST_EXPERIENCE =
	'admin-center-suggestions-unassigned-guest';
export const ADMIN_CENTER_SUGGESTIONS_INACTIVE_PAGES_EXPERIENCE =
	'admin-center-suggestions-inactive-pages';
export const ADMIN_CENTER_SUGGESTIONS_DEACTIVATED_OWNER_PAGES_EXPERIENCE =
	'admin-center-suggestions-deactivated-owner-pages';
// Feature discovery suggestions
export const ADMIN_CENTER_SUGGESTIONS_SPACE_MANAGER_EXPERIENCE =
	'admin-center-suggestions-space-manager';
export const ADMIN_CENTER_SUGGESTIONS_USER_ACCESS_EXPERIENCE =
	'admin-center-suggestions-user-access';

export const ADMIN_CENTER_SUGGESTIONS_QUICK_ACTION_ITEM_EXPERIENCE =
	'admin-center-suggestions-quick-action-item';
export const ADMIN_CENTER_SUGGESTIONS_METRIC_CTA_EXPERIENCE = 'admin-center-suggestions-metric-cta';

// Space Admin Center Page
export const SPACE_ADMIN_CENTER_EXPERIENCE = 'space-admin-center';
export const SPACE_ADMIN_CENTER_GROUPED_SUMMARY_EXPERIENCE =
	'space-admin-center-grouped-summary-section';
export const SPACE_ADMIN_CENTER_EXTERNAL_ACCESS_EXPERIENCE = 'space-admin-center-external-access';
export const SPACE_ADMIN_CENTER_SPACE_ADMINS_EXPERIENCE = 'space-admin-center-space-admins';
export const SPACE_ADMIN_CENTER_CONTENT_VIEWS_EXPERIENCE = 'space-admin-center-content-views';
export const SPACE_ADMIN_CENTER_TOP_CONTENT_VIEWS_EXPERIENCE =
	'space-admin-center-top-content-views';
export const SPACE_ADMIN_CENTER_CONTENT_CREATED_EXPERIENCE = 'space-admin-center-content-created';
export const SPACE_ADMIN_CENTER_CONTENT_UPDATED_EXPERIENCE = 'space-admin-center-content-updated';
export const SPACE_ADMIN_CENTER_TOTAL_CONTENT_EXPERIENCE = 'space-admin-center-total-content';
export const SPACE_ADMIN_CENTER_INACTIVE_PAGES_EXPERIENCE = 'space-admin-center-inactive-pages';
export const SPACE_ADMIN_CENTER_TOP_INACTIVE_PAGES_EXPERIENCE =
	'space-admin-center-top-inactive-pages';
export const SPACE_ADMIN_CENTER_DEACTIVATED_OWNER_PAGES_EXPERIENCE =
	'space-admin-center-deactivated-owner-pages';
export const SPACE_ADMIN_CENTER_TOP_DEACTIVATED_OWNER_PAGES_EXPERIENCE =
	'space-admin-center-top-deactivated-owner-pages';

// Dwell Event
export const DWELL_TIME_START_EXPERIENCE = 'dwell-time/start';

// Trial Components
export const TRIAL_COMPONENTS_ACTION_MENU_VIEW_EXPERIENCE = 'trial-components/action-menu-view';
export const TRIAL_COMPONENTS_LOZENGE_VIEW_EXPERIENCE = 'trial-components/lozenge-view';
export const TRIAL_COMPONENTS_FLAG_VIEW_EXPERIENCE = 'trial-components/flag-view';
export const TRIAL_COMPONENTS_PREMIUM_INSIGHTS_ACTION_MENU_ITEM_VIEW_EXPERIENCE =
	'trial-components/premium-insights-action-menu-item-view';

// Team Presence
export const TEAM_PRESENCE_AVATAR_GROUP_VIEW_EXPERIENCE = 'team-presence/avatar-group-view';
export const TEAM_PRESENCE_AVATAR_GROUP_MENU_VIEW_EXPERIENCE =
	'team-presence/avatar-group-menu-view';

// Flywheel Feature Discovery Experiences
// This is a sample, please add new experiences as applicable below
export const FLYWHEEL_FEATURE_DISCOVERY_EXPERIENCE = 'flywheel-feature-discovery';

// Quick Summary
export const QUICK_SUMMARY_EXPERIENCE = 'quick-summary';

// Quick Summary + whiteboards
export const QUICK_SUMMARY_WHITEBOARDS_EXPERIENCE = 'quick-summary-whiteboards';

// Quick Summary to page conversion
export const QUICK_SUMMARY_TO_PAGE_EXPERIENCE = 'quick-summary-to-page';

// Combined Comments Summary
export const COMMENTS_SUMMARY_EXPERIENCE = 'comments-summary';

// AI Content Catchup
export const AI_CONTENT_CATCHUP_EXPERIENCE = 'ai-content-catchup';

// AI Presentation Generation
export const AI_PRESENTATION_GENERATION_VIEW_EXPERIENCE = 'ai-presentation-generation-view';
export const AI_PRESENTATION_GENERATION_CREATE_EXPERIENCE = 'ai-presentation-generation-create';

// AI Snippet
export const AI_SNIPPET_EXPERIENCE = 'ai-snippet';

// Bulk Issue Create
export const AI_BULK_ISSUE_CREATE = 'ai-bulk-issue-create';

// AI Audio
export const AI_AUDIO = 'ai-audio';

// AI Title Generation
export const AI_TITLE_GENERATION = 'ai-title-generation';

// Editions Plan Selection Experiment
export const PLAN_COMPARISON_MODAL = 'plan-comparison-modal';

// Pendo agent installation
export const PENDO_INSTALLATION_EXPERIENCE = 'pendo-installation';

// AI Definitions (FKA Contextual Reading Aids)
export const READING_AIDS_EXPERIENCE = 'reading-aids';
export const AI_DEFINITIONS_AUTOHIGHLIGHT_EXPERIENCE = 'ai-definitions-autohighlight';

// External collab - Guest Request to Upgrade Experiment
export const VIEW_GUEST_REQUEST_TO_UPGRADE_EXPERIENCE = 'view-guest-request-to-upgrade';
export const SUBMIT_GUEST_REQUEST_TO_UPGRADE_EXPERIENCE = 'submit-guest-request-to-upgrade';

// External collab - Guest Request to Upgrade Side Nav Experiment
export const VIEW_GUEST_REQUEST_TO_UPGRADE_SIDE_NAV_EXPERIENCE =
	'view-guest-request-to-upgrade-side-nav';
export const SUBMIT_GUEST_REQUEST_TO_UPGRADE_SIDE_NAV_EXPERIENCE =
	'submit-guest-request-to-upgrade-side-nav';

// External collab - Guest Mentions Upgrade Flow Experiment
export const SUBMIT_GUEST_MENTIONS_UPGRADE_FLOW_EXPERIENCE = 'submit-guest-mentions-upgrade-flow';

// External collab - Guest Spaces Menu Request to Upgrade Experiment
export const VIEW_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE =
	'view-guest-spaces-menu-request-to-upgrade';
export const SUBMIT_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE =
	'submit-guest-spaces-menu-request-to-upgrade';

// Space soft deletion
export const RESTORE_SPACE_EXPERIENCE = 'restore-space';
export const HARD_DELETE_SPACE_EXPERIENCE = 'hard-delete-space';

// Frontend space permissions
export const VIEW_SPACE_PERMISSIONS = 'view-space-permissions';
export const EDIT_SPACE_PERMISSIONS = 'edit-space-permissions';

// Frontend JSM Banner in space permissions
export const VIEW_JSM_BANNER = 'view-jsm-banner';
export const DISABLE_JSM_PERMISSIONS = 'disable-jsm-permissions';

//Frontend default space permissions
export const VIEW_DEFAULT_SPACE_PERMISSIONS = 'view-default-space-permissions';
export const EDIT_DEFAULT_SPACE_PERMISSIONS = 'edit-default-space-permissions';

// All spaces table in Security - Space permissions
export const VIEW_ALL_SPACES_TAB = 'view-all-spaces-tab';

// Customize navigation page
export const COMPANY_HOME_BUILDER_LOAD_EXPERIENCE = 'company-home-builder-load';
export const COMPANY_HOME_BUILDER_EDIT_EXPERIENCE = 'company-home-builder-edit';
export const COMPANY_HOME_BUILDER_UPLOAD_EXPERIENCE = 'company-home-builder-edit/upload';

// Company hub
export const COMPANY_HUB_CREATION_EXPERIENCE = 'company-hub-creation';
export const COMPANY_HUB_CHANGE_HUB_NAME_EXPERIENCE = 'company-hub-change-hub-name';
export const FRONT_COVER_LOAD_EXPERIENCE = 'front-cover-load';
export const FRONT_COVER_PUBLISH_EXPERIENCE = 'front-cover-publish';
export const COMPANY_HUB_UNPUBLISH_HUB_EXPERIENCE = 'company-hub-unpublish-hub';
export const COMPANY_HUB_ANALYTICS_EXPERIENCE = 'company-hub-analytics';
export const COMPANY_HUB_ANALYTICS_BOX_ROW_EXPERIENCE = 'company-hub-analytics-box-row';
export const COMPANY_HUB_ANALYTICS_CHART_EXPERIENCE = 'company-hub-analytics-chart';
export const COMPANY_HUB_ANALYTICS_TABLE_EXPERIENCE = 'company-hub-analytics-table';
export const COMPANY_HUB_CARD_AI_DESCRIPTION_EXPERIENCE = 'company-hub-card-ai-description';

// End of Trial Modal
export const END_OF_TRIAL_MODAL_EXPERIENCE = 'end-of-trial-modal';
export const END_OF_TRIAL_NUMBER_OF_USERS_EXPERIENCE = 'end-of-trial-modal/number-of-users';
export const END_OF_TRIAL_GUEST_ACCESS_EXPERIENCE = 'end-of-trial-modal/guest-access';
export const END_OF_TRIAL_PAGE_INSIGHTS_EXPERIENCE = 'end-of-trial-modal/page-insights';
export const END_OF_TRIAL_PAGE_RESTRICTIONS_EXPERIENCE = 'end-of-trial-modal/page-restrictions';
export const END_OF_TRIAL_OSB_LINK = 'end-of-trial-modal/osb-link';

// Cards Image Upload
export const CARDS_IMAGE_UPLOAD_EXPERIENCE = 'cards-image-upload';

// Team calendars admin
export const ADMIN_TEAM_CALENDARS_LOAD_EXPERIENCE = 'admin-team-calendars-load';
export const ADMIN_TEAM_CALENDARS_UPDATE_EXPERIENCE = 'admin-team-calendars-update';

// Standard Differentiators
export const STANDARD_DIFFERENTIATORS_ONBOARDING_EXPERIENCE = 'standard-differentiators-onboarding';
export const STANDARD_DIFFERENTIATORS_HELP_EXPERIENCE = 'standard-differentiators-help';

// General configuration
export const ADMIN_GENERAL_CONFIGURATION_LOAD_EXPERIENCE = 'admin-general-configuration-load';
export const ADMIN_GENERAL_CONFIGURATION_UPDATE_EXPERIENCE = 'admin-general-configuration-update';
export const ADMIN_GENERAL_CONFIGURATION_UPDATE_FAVICON_UPLOAD_EXPERIENCE =
	'admin-general-configuration-update/favicon-upload';

export const NOTIFICATIONS_SITE_SETTINGS_LOAD_EXPERIENCE = 'notifications-site-settings-load';
export const NOTIFICATIONS_SITE_SETTINGS_UPDATE_EXPERIENCE = 'notifications-site-settings-update';

// Configure code macro
export const ADMIN_CONFIGURE_CODE_MACRO_LOAD_EXPERIENCE = 'admin-configure-code-macro-load';
export const ADMIN_CONFIGURE_CODE_MACRO_UPDATE_EXPERIENCE = 'admin-configure-code-macro-update';

// Configure default space logo
export const ADMIN_DEFAULT_SPACE_LOGO_CONFIG_LOAD_EXPERIENCE =
	'admin-default-space-logo-config-load';
export const ADMIN_DEFAULT_SPACE_LOGO_CONFIG_MEDIA_SERVICE_LOAD_EXPERIENCE =
	'admin-default-space-logo-config-media-service-load';
export const ADMIN_DEFAULT_SPACE_LOGO_CONFIG_UPDATE_EXPERIENCE =
	'admin-default-space-logo-config-update';

// Header and footer
export const ADMIN_HEADER_AND_FOOTER_LOAD_EXPERIENCE = 'admin-header-and-footer-load';
export const ADMIN_HEADER_AND_FOOTER_UPDATE_EXPERIENCE = 'admin-header-and-footer-update';

// Page layout
export const SPACE_SETTINGS_ADMIN_PAGE_LAYOUT_LOAD_EXPERIENCE =
	'space-settings-admin-page-layout-load';
export const SPACE_SETTINGS_ADMIN_PAGE_LAYOUT_UPDATE_EXPERIENCE =
	'space-settings-admin-page-layout-update';

// Security configuration
export const ADMIN_SECURITY_CONFIGURATION_LOAD_EXPERIENCE = 'admin-security-configuration-load';
export const ADMIN_SECURITY_CONFIGURATION_UPDATE_EXPERIENCE = 'admin-security-configuration-update';

// Templates and blueprints
export const ADMIN_TEMPLATES_AND_BLUEPRINTS_LOAD_EXPERIENCE = 'admin-templates-and-blueprints-load';
export const ADMIN_TEMPLATES_AND_BLUEPRINTS_PAGE_TEMPLATES_LOAD_EXPERIENCE =
	'admin-templates-and-blueprints-load/page-templates';
export const ADMIN_TEMPLATES_AND_BLUEPRINTS_PAGE_BLUEPRINTS_LOAD_EXPERIENCE =
	'admin-templates-and-blueprints-load/page-blueprints';
export const ADMIN_TEMPLATES_AND_BLUEPRINTS_SYSTEM_TEMPLATES_LOAD_EXPERIENCE =
	'admin-templates-and-blueprints-load/system-templates';

export const SPACE_SETTINGS_ADMIN_TEMPLATES_AND_BLUEPRINTS_LOAD_EXPERIENCE =
	'space-settings-admin-templates-and-blueprints-load';

// Import spaces
export const ADMIN_IMPORT_SPACES_CONFIGURATION_LOAD_EXPERIENCE =
	'admin-import-spaces-configuration-load';
export const ADMIN_IMPORT_SPACES_IMPORT_EXPERIENCE = 'admin-import-spaces-import';

// Trashed spaces
export const ADMIN_TRASHED_SPACES_LOAD_EXPERIENCE = 'admin-trashed-spaces-load';
export const ADMIN_RESTORE_SPACE_EXPERIENCE = 'admin-trashed-spaces-restore';
export const ADMIN_HARD_DELETE_SPACE_EXPERIENCE = 'admin-trashed-spaces-hard-delete';

// Color scheme
export const ADMIN_COLOR_SCHEME_LOAD_EXPERIENCE = 'admin-color-scheme-load';
export const ADMIN_COLOR_SCHEME_UPDATE_EXPERIENCE = 'admin-color-scheme-update';

// Application navigator
export const ADMIN_APPLICATION_NAVIGATOR_LOAD_EXPERIENCE = 'admin-application-navigator-load';
export const ADMIN_APPLICATION_NAVIGATOR_UPDATE_EXPERIENCE = 'admin-application-navigator-update';
export const ADMIN_APPLICATION_NAVIGATOR_ADD_EXPERIENCE = 'admin-application-navigator-add';

// Themes
export const ADMIN_THEMES_LOAD_EXPERIENCE = 'admin-themes-load';
export const ADMIN_THEMES_UPDATE_EXPERIENCE = 'admin-themes-update';
export const SPACE_SETTINGS_ADMIN_THEMES_LOAD_EXPERIENCE = 'space-settings-admin-themes-load';
export const SPACE_SETTINGS_ADMIN_THEMES_UPDATE_EXPERIENCE = 'space-settings-admin-themes-update';

// Jira macro repair
export const ADMIN_JIRA_MACRO_REPAIR_LOAD_EXPERIENCE = 'admin-jira-macro-repair-load';
export const ADMIN_JIRA_MACRO_REPAIR_UPDATE_EXPERIENCE = 'admin-jira-macro-repair-update';

// PDF Export
export const ADMIN_PDF_EXPORT_LOAD_EXPERIENCE = 'admin-pdf-export-load-experience';
export const ADMIN_PDF_EXPORT_UPDATE_EXPERIENCE = 'admin-pdf-export-update-experience';
export const SPACE_SETTINGS_ADMIN_PDF_EXPORT_LOAD_EXPERIENCE =
	'space-settings-admin-pdf-export-load-experience';
export const SPACE_SETTINGS_ADMIN_PDF_EXPORT_UPDATE_EXPERIENCE =
	'space-settings-admin-pdf-export-update-experience';
export const SPACE_SETTINGS_EXPORT_SPACE_LOAD_PAGE_TREE = 'export-page-tree-load';

// Macro usage
export const ADMIN_MACRO_USAGE_LOAD_EXPERIENCE = 'admin-macro-usage-load';

// Confluence watch dialog
export const WATCH_DIALOG_WATCH_EXPERIENCE = 'watch-dialog-watch';
export const WATCH_DIALOG_WATCH_CHILDREN_EXPERIENCE = 'watch-dialog-watch-children';

// Auto upgrade awareness
export const AUA_FREE_PILL_EXPERIENCE = 'auto-upgrade-awareness-free-pill';
export const AUA_MODAL_EXPERIENCE = 'auto-upgrade-awareness-modal';

// Space settings
export const SPACE_SETTINGS_ADMIN_LOOK_AND_FEEL_LOAD_EXPERIENCE =
	'space-settings-admin-look-and-feel-experience';

// User access
export const USER_ACCESS_UPDATE_EXPERIENCE = 'user-access-update';

// Space permissions report
export const SPACE_PERMISSIONS_REPORT_EXPERIENCE = 'user-access-space-permissions-report';

// Page to folder conversion
export const CONVERT_PAGE_TO_FOLDER_EXPERIENCE = 'convert-page-to-folder';

//Share and restrict dialog
export const SHARE_AND_RESTRICT_BUTTON_LOAD_EXPERIENCE = 'share-and-restrict-button-load';
export const SHARE_AND_RESTRICT_DIALOG_LOAD_EXPERIENCE = 'share-and-restrict-dialog-load';
export const SHARE_AND_RESTRICT_DIALOG_APPLY_ACTION_EXPERIENCE =
	'share-and-restrict-dialog-apply-action';

// Notifications - Flyout messaging experiment
export const FLYOUT_MESSAGING_EXPERIENCE = 'flyout-messaging';

// Recommend buy standard on premium trial
export const RECOMMEND_BUY_STANDARD_PREMIUM_TRIAL = 'confluence-eot-premium-recommend-standard-buy';

// Space roles
export const RBAC_VIEW_EXPERIENCE = 'view-space-roles';
export const RBAC_EDIT_EXPERIENCE = 'edit-space-roles';
export const DEFAULT_RBAC_VIEW_EXPERIENCE = 'view-default-space-roles';
export const DEFAULT_RBAC_EDIT_EXPERIENCE = 'edit-default-space-roles';
export const SITE_RBAC_VIEW_EXPERIENCE = 'view-site-roles';
export const SITE_RBAC_EDIT_EXPERIENCE = 'edit-site-roles';

// Space details
export const SPACE_DETAILS_LOAD_EXPERIENCE = 'space-details-load';
export const SPACE_DETAILS_UPDATE_EXPERIENCE = 'space-details-update';
export const SPACE_DETAILS_SPACE_LABEL_SEARCH_EXPERIENCE = 'space-details-space-label-search';

// Content Wrapper
export const SIDEBAR_DETAILS_PANEL_LOAD_EXPERIENCE = 'sidebar-details-panel-load';

// Migrations - Import Content
export const IMPORT_CONTENT_EXPERIENCE = 'import-content';
export const IMPORT_MIRO_USERS = 'import-miro-users';

// Mpucup free to standard feature gates modal
export const MPUCUP_FREE_TO_STANDARD_FG_MODAL_EXPERIENCE = 'mpucup-free-to-standard-fg-modal';
