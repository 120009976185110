import type { Parameters, Extension, ExtensionHandler } from '@atlaskit/editor-common/extensions';

import type { ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality/types';

export type KeyedMacros = { [key: string]: LegacyMacroManifest[] };

export type ButtonConfig = { action: string | null; label: string; key: string };

export type LegacyMacroManifest = {
	aliases: string[];
	buttons: ButtonConfig[];
	hidden: boolean;
	anyParameterRequired?: boolean;
	icon: {
		width: number;
		location: string;
		height: number;
		relative: boolean;
	} | null;
	description: string;
	pluginKey: string;
	autoconvertData: any;
	title: string;
	bodyDeprecated: boolean;
	alternateId: string | null;
	macroName: string;
	moduleKey?: string | null;
	gadgetUrl?: null;
	categories: string[];
	defaultLayout: string | null;
	customConfigEditor: boolean;
	alwaysShowConfig?: boolean;
	formDetails: {
		macroName: string;
		documentationUrl: string | null;
		schemaVersion: number;
		freeform: boolean;
		notationHelp: any;
		excludedSchemaMigrationPoints: never[];
		showDefaultParamInPlaceholder: boolean;
		documentationLink: any;
		body: {
			bodyType: string;
			hidden: boolean;
			description: string;
			outputType: string | null;
			label: string;
		};
		parameters: LegacyParameter[];
	};
};

export type LegacyParameter = {
	aliases: string[];
	hidden: boolean;
	defaultValue: string | null;
	displayName: string;
	multiple: boolean;
	description: string | null;
	pluginKey: string;
	type: { name: string };
	required: boolean;
	macroName: string;
	name: string;
	options: {
		includeDatePath?: string;
		derived?: string;
		defaultFields?: string;
		defaultValue?: string;
		requiredFields?: string;
		type?: string;
		delimiter?: string;
		showValueInPlaceholder?: string;
		showNameInPlaceholder?: string;
	};
	enumValues: string[] | null;
};

export type MacroParams = {
	[key: string]: any;
	isMissingRequiredParameters?: boolean;
};

export type MacroData = {
	pluginKey: string;
	macroName: string;
};

export enum MappedFieldTypes {
	int = 'number',
	percentage = 'string',
	url = 'string',
	cql = 'dynamic-fieldset',
	relativedate = 'string',
	group = 'string',
	color = 'string',
}

export type Entry<T> = [string, T];

export type ConfluencePageContext = {
	contentId: string;
	features: {
		configPanel?: {
			enabled: boolean;
			optedOut: Array<string>;
		};
		elementBrowser?: boolean;
	};
	spaceKey?: string;
	spaceId?: string;
};

export type Option = {
	value: string;
	description?: string;
	label: string;
	icon?: string | React.ReactNode | null | undefined;
};

export type Resolver = (
	searchTerm?: string | undefined,
	selectedValue?: string | string[] | undefined,
) => Promise<Option[]>;

export type RendererExtensionHandlers<T extends Parameters = any> = {
	[key: string]: Extension<T> | ExtensionHandler<T> | ExtensionHandlerWithReferenceFn<T>;
};

export type OnCompleteCallbackFnType = ({
	macro,
	isBodiedExtension,
}: {
	macro: string | undefined;
	isBodiedExtension: boolean;
}) => void;
