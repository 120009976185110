import type {
	Fieldset,
	FieldDefinition,
	EnumField,
	CustomField,
	NumberField,
	BooleanField,
} from '@atlaskit/editor-common/extensions';

import type { LegacyParameter, MacroData } from './extensionTypes';
import { MappedFieldTypes } from './extensionTypes';
import { getCQLFields } from './cql/fields';
/**
 * Confluence returns a namespace inside description and label when the field is empty.
 * To map it to the new extension api, we need to remove it or replace it depending on the case.
 *
 * The original code can be found at https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence/browse/confluence-plugins/confluence-editor-plugins/confluence-macro-browser/src/main/resources/js/macro-browser-editor.js#441
 * @param value
 * @param fallback
 * @param params
 * @param macroData
 * @param fieldType
 */
const safeGetBodyValue = (
	value: string,
	fallback: string,
	params: LegacyParameter,
	macroData: MacroData,
	fieldType: 'label' | 'desc',
) => {
	const { pluginKey, macroName } = macroData;
	if (value === [pluginKey, macroName, 'param', params.name, fieldType].join('.')) {
		return fallback;
	}

	return value;
};

const getBaseAttributes = (params: LegacyParameter, macroData: MacroData) => {
	const { displayName, name, description } = params;

	return {
		label: safeGetBodyValue(displayName, name, params, macroData, 'label'),
		name: params.name || 'defaultParameterValue',
		defaultValue: params.defaultValue as any,
		description: safeGetBodyValue(description ?? '', '', params, macroData, 'desc'),
		isMultiple: !!params.multiple,
		isRequired: !!params.required,
		isHidden: !!params.hidden,
	};
};

const isNativeFieldType = (fieldType: string) => {
	return /^(enum|string|number|boolean|date)$/.test(fieldType);
};

export const transformFormDetailsIntoFields = (
	params: LegacyParameter,
	macroData: MacroData,
): FieldDefinition => {
	const fieldType = transformFieldType(params.type.name);
	const baseAttributes = getBaseAttributes(params, macroData);

	if (fieldType === 'custom') {
		const customField: CustomField = {
			...baseAttributes,
			type: 'custom',
			options: {
				resolver: {
					type: params.type.name,
				},
			},
		};

		return customField;
	}

	if (params.type.name === 'cql') {
		let label = safeGetBodyValue(params.displayName, params.name, params, macroData, 'label');

		if (label === 'cql') {
			label = 'CQL';
		}

		const cqlField: Fieldset = {
			label,
			name: params.name || 'defaultParameterValue',
			fields: getCQLFields(),
			type: 'fieldset',
			options: {
				isDynamic: true,
				transformer: {
					type: params.type.name,
				},
			},
		};

		return cqlField;
	}

	if (fieldType === 'enum') {
		const enumField = {
			...baseAttributes,
			type: 'enum',
			style: 'select',
			defaultValue: baseAttributes.defaultValue ?? undefined,
			items: (params.enumValues || []).map((item) => ({
				label: item,
				value: item,
			})),
		} as EnumField;

		return enumField;
	}

	// Transform default value of number from `null` -> `0`
	if (fieldType === 'number') {
		const numberField = {
			...baseAttributes,
			type: 'number',
			defaultValue: baseAttributes.defaultValue ?? undefined,
		} as NumberField;

		return numberField;
	}

	// Transform default value of number from `"false"` -> `false`
	if (fieldType === 'boolean') {
		const booleanField = {
			...baseAttributes,
			type: 'boolean',
			isRequired: false,
			defaultValue:
				typeof baseAttributes.defaultValue === 'string'
					? baseAttributes.defaultValue !== 'false'
					: !!baseAttributes.defaultValue,
		} as BooleanField;

		return booleanField;
	}

	const field: Exclude<FieldDefinition, CustomField> = {
		...baseAttributes,
		type: fieldType as any,
	};

	return field;
};

const isMappedField = (fieldType: string): fieldType is keyof typeof MappedFieldTypes =>
	fieldType in MappedFieldTypes;

const transformFieldType = (fieldType: keyof typeof MappedFieldTypes | string) => {
	if (isMappedField(fieldType)) {
		return MappedFieldTypes[fieldType];
	}

	if (isNativeFieldType(fieldType)) {
		return fieldType;
	}

	return 'custom';
};
