import gql from 'graphql-tag';

export const SpaceRootQuery = gql`
	query SpaceViewsSpaceRootQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			homepageId
			operations {
				operation
				targetType
			}
		}
	}
`;

export const SpaceViewsContentQuery = gql`
	query SpaceViewsContentQuery($first: Int!, $spaceKey: String!, $token: String, $sort: String!) {
		content(first: $first, spaceKey: $spaceKey, orderby: $sort, after: $token, status: "current") {
			nodes {
				id
				title
				hasRestrictions
				hasInheritedRestrictions
				links {
					webui
					base
				}
				properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`;

export const RecentlyViewedQuery = gql`
	query SpaceViewsRecentlyViewedQuery($cloudId: ID!, $first: Int!, $after: String, $spaceId: ID!) {
		activities {
			myActivities {
				viewed(
					filters: [
						{
							type: AND
							arguments: {
								products: [CONFLUENCE]
								objectTypes: [PAGE]
								cloudIds: [$cloudId]
								containerIds: [$spaceId]
							}
						}
					]
					first: $first
					after: $after
				) {
					edges {
						node {
							id
							object {
								id
								localResourceId
								content {
									id
									title
									hasRestrictions
									hasInheritedRestrictions
									properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
										nodes {
											id
											key
											value
											version {
												number
											}
										}
									}
								}
							}
						}
						cursor
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
`;
