import {
	createStore,
	createSubscriber,
	createContainer,
	createStateHook,
	createHook,
} from 'react-sweet-state';
import type { Action } from 'react-sweet-state';
import type { DocumentNode } from 'graphql';

import { getApolloClient } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { fg } from '@confluence/feature-gating';

import type { MacrosQueryTypes } from '.';

import { MacrosQuery } from './Macros/MacrosQuery.graphql';
import { getMultiMacroQueryBlocklistFF } from './useMultiMacroQuery';
import type { MacrosQueryVariables } from './Macros/__types__/MacrosQuery';

type StoreState = {
	contentId?: string;
	featureFlags?: Record<string, any>;
	revision?: string;
	complete: boolean;
	data: any;
	cursor?: string;
	macroData: Record<string, any>; // macroId -> data
	error?: boolean;
};

type ContainerParams = {
	contentId: string;
	contentRendererMode: string;
	featureFlags: Record<string, any>;
	cursor?: string;
	revision?: string;
	useServerRenderedData?: boolean;
};

const DEFAULT_PRELOAD_PAGE_SIZE = 8;
const DEFAULT_PAGE_SIZE = 10;

const actions = {
	queryMacros:
		({
			contentId,
			contentRendererMode,
			featureFlags,
			cursor,
			revision,
			useServerRenderedData = false,
		}): Action<StoreState> =>
		async ({ getState, setState, dispatch }) => {
			// avoid querying if already in progress
			if (
				getState().contentId === contentId &&
				getState().revision === revision &&
				getState().cursor === cursor
			) {
				return;
			}

			setState({
				contentId,
				revision,
				cursor,
				complete: false,
			});

			let mode = contentRendererMode.toUpperCase();
			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
			if (!process.env.REACT_SSR) {
				mode = window.location.pathname.startsWith('/wiki/pdf/') ? 'PDF' : mode;
			}

			const queryOptions: {
				query: DocumentNode;
				variables: MacrosQueryVariables;
				context: Record<string, any>;
			} = {
				query: MacrosQuery,
				variables: {
					contentId,
					mode,
					blocklist: getMultiMacroQueryBlocklistFF(),
					first: !cursor ? DEFAULT_PRELOAD_PAGE_SIZE : DEFAULT_PAGE_SIZE,
					after: cursor,
				},
				context: {
					allowOnExternalPage: true,
				},
			};

			// don't include revision if in SSR otherwise it will cause a cache miss between
			// SSR preload where revision is unknown and SSR render when this action is called
			if (
				// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				(!process.env.REACT_SSR && !fg('confluence_frontend_multi_macro_hydration_fix')) ||
				(!useServerRenderedData && fg('confluence_frontend_multi_macro_hydration_fix'))
			) {
				queryOptions.variables.refetchToken = revision;
			}
			let data;
			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
			if (process.env.REACT_SSR && !getState().cursor) {
				data = getApolloClient().readQuery<MacrosQueryTypes>(queryOptions);
			} else {
				const shouldUseServerRenderedData =
					useServerRenderedData &&
					!getState().cursor &&
					fg('confluence_frontend_multi_macro_hydration_fix');

				if (shouldUseServerRenderedData) {
					try {
						data = getApolloClient().readQuery<MacrosQueryTypes>(queryOptions);
					} catch (e) {
						getMonitoringClient().submitError(e, {
							attribution: 'backbone',
						});
					}
				}

				if (!data || !data?.macros || !shouldUseServerRenderedData) {
					const result = await getApolloClient().query<MacrosQueryTypes>(queryOptions);

					if (result.errors) {
						getMonitoringClient().submitError(result.errors, {
							attribution: 'backbone',
						});

						setState({
							error: true,
						});
					}

					data = result.data;
				}
			}

			const { nodes = [], pageInfo = {} } = data?.macros;
			const macros = nodes?.reduce((nodeMap, node) => {
				if (node) {
					const key = getMacrosCacheKey(node.contentId || '', node.macroId || '');
					nodeMap[key] = node?.renderedMacro;
				}
				return nodeMap;
			}, {});

			setState({
				macroData: {
					...getState().macroData,
					...macros,
				},
			});

			if (pageInfo.hasNextPage) {
				void dispatch(
					actions.queryMacros({
						contentId,
						contentRendererMode,
						featureFlags,
						cursor: pageInfo.endCursor,
						revision,
					}),
				);
			} else {
				setState({
					complete: true,
				});
			}
		},
	//only for testing
	resetState:
		(): Action<StoreState> =>
		({ setState }) => {
			setState({
				complete: false,
				data: {},
				macroData: {},
				error: false,
				contentId: undefined,
				revision: undefined,
				cursor: undefined,
			});
		},
};

const store = createStore<StoreState, typeof actions>({
	initialState: { complete: false, data: {}, macroData: {} },
	actions,
	name: 'MultiMacroQueryStore',
});

export const MultiMacroQueryContainer = createContainer(store, {
	onInit:
		() =>
		(
			{ dispatch },
			{
				contentId,
				contentRendererMode,
				featureFlags,
				cursor,
				revision,
				useServerRenderedData,
			}: ContainerParams,
		) => {
			void dispatch(
				actions.queryMacros({
					contentId,
					contentRendererMode,
					featureFlags,
					cursor,
					revision,
					useServerRenderedData,
				}),
			);
		},
	onUpdate:
		() =>
		(
			{ dispatch },
			{
				contentId,
				contentRendererMode,
				featureFlags,
				cursor,
				revision,
				useServerRenderedData,
			}: ContainerParams,
		) => {
			void dispatch(
				actions.queryMacros({
					contentId,
					contentRendererMode,
					featureFlags,
					cursor,
					revision,
					useServerRenderedData,
				}),
			);
		},
});

function getMacrosCacheKey(contentId: string, macroId: string) {
	return `${contentId}::${macroId ?? 0}`;
}

function multiMacroQuerySelector(
	state: StoreState,
	params: { macroId: string; contentId: string },
) {
	const key = getMacrosCacheKey(params.contentId, params.macroId);
	const macroData = state.macroData?.[key];

	return {
		loading: !Boolean(macroData) && !state.complete,
		error: !Boolean(macroData) && state.error,
		renderedMacro: macroData || null,
		complete: state.complete,
	};
}

export const useMultiMacroQuery = createStateHook(store, {
	selector: multiMacroQuerySelector,
});

export const MultiMacroQuerySubscriber = createSubscriber(store, {
	selector: multiMacroQuerySelector,
});

// exported for testing store actions
export const useMultiMacroQueryStoreHook = createHook(store);
